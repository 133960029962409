/*= ========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

export default [
  {
    url: '/passer-commande',
    name: 'Commander',
    slug: 'commandes',
    icon: 'FilePlusIcon',
  },
  {
    url: '/historique',
    name: 'Historique',
    slug: 'Historique',
    icon: 'ShoppingCartIcon',
  },
  {
    url: '/agenda',
    name: 'Agenda',
    slug: 'agenda',
    icon: 'CalendarIcon',
  },
  {
    url: '/rendez-vous',
    name: 'Rendez-Vous',
    slug: 'rendezVous',
    icon: 'BookmarkIcon',
    submenu: [
      {
        url: { name: 'user-appointments', query: { status: 'avenir' } },
        name: 'A venir',
        slug: 'avenir',
        i18n: 'List',
      },
      {
        url: { name: 'user-appointments', query: { status: 'astatuer' } },
        name: 'A statuer',
        slug: 'astatuer',
        i18n: 'List',
      },
      {
        url: { name: 'user-appointments', query: { status: 'realise' } },
        name: 'Réalisé',
        slug: 'realise',
        i18n: 'List',
      }, {
        url: { name: 'user-appointments', query: { status: 'annules' } },
        name: 'Annulés',
        slug: 'termine',
        i18n: 'List',
      }, {
        url: { name: 'user-appointments', query: { status: 'enAttenteAnnul' } },
        name: 'En attente d\'annulation',
        slug: 'enAttenteAnnul',
        i18n: 'List',
      },

    ],
  },

];
